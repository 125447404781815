<template>
  <main class="main" data-view="bidaskbit">
    <section class="section" id="s1">
      <div class="container">
        <div class="row mb-4 mb-md-5 pb-md-4">
          <div class="col-12 col-md-10 offset-md-1 col-lg-6 offset-lg-0">
            <ui-text tag="3" class="tc-yellow tw-500 lh-35 ta-center ta-lg-start mb-4">
              {{ cc.s1.pre }}
            </ui-text>
            <ui-title tag="2" class="ta-center ta-lg-start mb-4">
              {{ cc.s1.title }}
            </ui-title>
            <ui-text class="tc-cadet ts-18 ts-md-30 tw-500 lh-150 ta-center ta-lg-start mb-4">
              {{ cc.s1.text[0] }}
            </ui-text>
            <ui-text tag="3" class="tc-gray lh-175 ta-center ta-lg-start mb-4">
              {{ cc.s1.text[1] }}
            </ui-text>
          </div>
          <div class="col-12 col-md-10 offset-md-1 col-lg-6 offset-lg-0">
            <div class="wrapper d-flex align-items-center">
              <div class="img img--bidaskbit mx-auto">
                <img src="/img/pages/bidaskbit/img-s1-1.svg" alt="bidaskbit" />
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-4 mb-md-5 pb-md-4">
          <div class="col-12 col-md-10 offset-md-1 col-lg-6 offset-lg-0 mb-4">
            <div class="bab bab--left">
              <div class="wrapper d-flex flex-column justify-content-center">
                <ui-text class="tc-white ts-18 ts-md-20 lh-175 ta-center mb-2">
                {{ cc.s1.blocks[0].title }}
              </ui-text>
              <ui-text tag="7" class="tc-white tw-600 lh-125 ta-center">
                {{ cc.s1.blocks[0].text }}
              </ui-text>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-10 offset-md-1 col-lg-6 offset-lg-0">
            <div class="bab bab--right">
              <div class="wrapper d-flex flex-column justify-content-center">
                <ui-text class="tc-white ts-18 ts-md-20 lh-175 ta-center mb-2">
                {{ cc.s1.blocks[1].title }}
              </ui-text>
              <ui-text tag="7" class="tc-white tw-600 lh-125 ta-center">
                {{ cc.s1.blocks[1].text }}
              </ui-text>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <ui-btn :data="cc.s1.btn[0]" type="1" class="btn-w-160 btn-w-sm-200 btn-s1 btn-sm-s2 mx-auto"/>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="s2">
      <comp-card img="3">
        <div class="wrapper d-flex flex-column justify-content-center">
          <ui-title tag="4" class="ta-center ta-lg-start mb-4">
            {{ cc.s2.title }}
          </ui-title>
          <ui-text tag="3" class="tc-gray lh-35 ta-center ta-lg-start mb-md-4">
            {{ cc.s2.text }}
          </ui-text>
        </div>
      </comp-card>
    </section>
  </main>
</template>
<script>
import compCard from '@/components/Card'
import uiTitle from '@/components/UI/Title'
import uiText from '@/components/UI/Text'
import uiBtn from '@/components/UI/Btn'

export default {
  components: {
    compCard, uiTitle, uiText, uiBtn
  },
  computed: {
    cc () {
      return this.$t('pages.bidaskbit')
    }
  },
  mounted () {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }
}
</script>
